@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .cos-container {
        @apply px-4 sm:px-8 md:px-10 bscreen:px-14 lg:px-20
    }

    .heading2{
        @apply text-3xl font-medium
    }
}

@layer components {
    .body {
        @apply pt-3 font-body font-light text-sm text-txtcl
    }

    .heading1{
        @apply font-heading text-5xl font-medium leading-20
    }

    .navbar {
        @apply cos-container h-16 flex items-center justify-between 
    }

    .logo {
        @apply flex items-center gap-2 grow
    }

    .navigations {
        @apply flex justify-between grow
    }

    .navigation {
        @apply w-80 flex justify-between gap-3 
    }

    .cta__btn {
        @apply flex gap-3   
    }

    .btn-buy {
        @apply w-24 h-8 bg-primary-300 text-white rounded-md
    }

    .btn-contact {
        @apply w-24 h-8 border-2 border-primary-300 rounded-md
    }

    .img-flower1 {
        @apply translate-x-16 -translate-y-5 z-0 
    }
    
    .img-flower2 {
        @apply translate-y-24 -translate-x-12 z-0
    }

    .hero-section{
        @apply h-[35rem] cos-container mt-20 grid grid-rows-5 grid-cols-9
    }

    .header__heading{
        @apply col-start-1 col-end-4 row-start-1 row-end-3 
    }

    .header__sofa{
        @apply flex col-start-5 col-span-full
    }

    .header__cta{
        @apply row-start-3 row-end-4 col-start-5 col-end-6 -translate-y-4
    }

    .header__dinning{
        @apply col-start-8 col-span-full row-start-3 justify-self-end
    }

    .header__bed{
        @apply row-start-4 col-start-1 col-end-7 flex
    }

    .preview{
        @apply mt-32 pl-20 flex gap-6
    }

    .preview__scrollbar{
        @apply h-24 w-1 bg-zinc-300 rounded-full
    }

    .preview__scrollbar--fill{
        @apply h-10 bg-zinc-600
    }

    .preview__details{
        @apply relative w-full flex items-center bg-primary-200
    }

    .preview__details--text{
        @apply max-w-lg h-[22rem] px-10 flex items-center text-white bg-primary-300 
    }
    
    .preview__details--img{
        @apply absolute right-0 -top-28 max-w-lg 
    }

    .services{
        @apply mt-28 cos-container
    }

    .services__items{
        @apply flex gap-6 mt-10 [&>*]:flex [&>*]:flex-col [&>*]:items-center [&>*]:gap-4 [&>*]:text-center [&>*]:text-white [&>*]:py-11 [&>*]:px-8 [&>*]:rounded-xl
    }

    .service__quality{
        @apply bg-primary-400
    }

    .service__functionality{
        @apply bg-primary-300
    }

    .service__sustainability{
        @apply bg-primary-200 text-primary-500 
    }

    .products{
        @apply mt-24 pt-8 pb-28 cos-container text-center bg-gray-200
    }

    .product__items{
        @apply mt-20 flex items-center justify-between [&>*]:text-center
    }

    .product__item--pricing{
        @apply w-max mx-auto px-8 border-[1px] border-gray-500 rounded-full 
    }

    .review{
        @apply relative pt-10 pb-40 bg-primary-500 text-white 
    }

    .review__quote{
        @apply my-10 flex justify-center text-txtcl
    }

    .review__scrollbar {
        @apply h-1 w-80 mx-auto bg-zinc-300 rounded-full
    }

    .review__scrollbar--fill {
        @apply h-1 w-20 bg-zinc-500
    }

    .img-flower3{
        @apply absolute right-0 top-2
    }

    .img-flower4{
        @apply absolute bottom-8
    }

    .footer{
        @apply cos-container py-10
    }
}